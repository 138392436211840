// import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
// import Test from './pages/test';

function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;

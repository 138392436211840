import React from 'react';
import './home.css';
import logo from "../assets/logo2.png";
import user from '../assets/user.png';
import Typewriter from "typewriter-effect";
import card1 from "../assets/economics.jpg";
import card2 from "../assets/eco.jpg";
import card3 from "../assets/accounting.jpeg";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner from '../assets/finalShutter.svg'
import { Rating } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Facebook, LinkedIn, YouTube } from '@mui/icons-material';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import Rating from '@mui/material';


export default function Home() {
  const [value, setValue] = React.useState(5);

  const questions = [
    {
      question: "What is the approach followed by us?",
      answer: "At Growth Hub, our approach to lesson planning is entirely tailored to your individual needs. We begin by assessing your strengths, weaknesses, and unique learning style to establish clear objectives.",
    },
    {
      question: "What are Growth Hub's specialities? ",
      answer: "Growth Hub specializes in providing highly specialized and personalized academic mentoring exclusively for Commerce subjects. Whether you're preparing for IGCSE, A-levels, IBDP, CBSE, or ICSE exams, we are here to assist you.",
    },
    {
      question: "Our Mission",
      answer: "At Growth Hub, our mission is to empower students in to unlock their full academic potential. With a focus on IGCSE, A-levels, IBDP, CBSE, and ICSE students, we are dedicated to helping you achieve your academic goals. Our personalized mentoring approach is designed to foster a deeper understanding of Commerce subjects, guiding you towards a journey of academic excellence.",
    },
  ];
    return (
      <>

      <div className='home'>
        <div className='navbar-main'>
          <img src={logo} alt="growthHub-logo" className='logo' />
          <div className='rightInner-navbar'>
            <a href='#courses-container' className='navbar-links'>Courses</a>
            <a href='#testimonials' className='navbar-links'>Testimonials</a>
            <a href='#contact' className='navbar-links'>Contact</a>
          </div>
        </div>
        <div className="header-container">
        <div className="header-text">
        <Typewriter
            onInit={(typewriter) => {
                typewriter
                    .typeString("Welcome...")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("Unlock Your Academic Potential ")
                    .pauseFor(500)
                    // .deleteAll()
                    .typeString("<br />with Growth Hub")
                    .start();
            }}
          />
          </div>
          <img src={banner} className='banner' alt='banner'/>
        </div>
      </div>
      <div className="courses-container" id='courses-container'>
            <h1 className='courses-header'>Subjects we offer</h1>
            <div className='card-container' id='test'>
            <div className="course-card">
            <div  className="course-card-img">
              <img src={card1} className="cardImage" alt=""/>
            </div>
            <div className="course-card-content">
              <h4 className='cardText'>Economics</h4>
            </div>
          </div>

          <div className="course-card">
          <div  className="course-card-img">
            <img src={card2} className="cardImage" alt=""/>
          </div>
          <div className="course-card-content">
            <h4 className='cardText'>Business Studies</h4>
          </div>
        </div>


        <div className="course-card">
        <div  className="course-card-img">
          <img src={card3} className="cardImage" alt=""/>
        </div>
        <div className="course-card-content">
          <h4 className='cardText'>Accounting</h4>
        </div>
      </div>

            </div>
      </div>


      <div id="testimonials" className='testimonials'>
      <div className='carousel-container'>
      <div className="heading white-heading">
      Testimonial
  </div>
      <Carousel 
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      >
        <div className='carousel-inner-container'>
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
                                <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer "/>

           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Devi Mehta</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"Studying Business Studies with Jainil Sir has been a game-changer. His expertise and teaching approach have helped me excel in this subject. I feel well-prepared for any business question."</div>
  
    </div>
        
        </div>
        <div className='carousel-inner-container'>
         
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
                <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer "/>
           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Riya Majithia</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"Accountancy used to be a puzzle for me until I joined Jainil Sir's classNamees. His step-by-step guidance and real-life examples have made a significant difference in my understanding. I owe my accounting success to him."</div>
  
    </div>
        
        
        
        
        
        
        </div>
        <div className='carousel-inner-container'>
          
        
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
              <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer "/>

           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Aditya Singh</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"Jainil Sir's guidance in Economics has been invaluable. His explanations are crystal clear, making complex economic theories easier to grasp. I'm now more confident in my understanding of the subject."
        </div>
  
    </div>
        
        
        
        
        
        </div>

        <div className='carousel-inner-container'>
          
        
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
                <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer"/>

           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Anand Mhatre</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"I can't express how grateful I am for Jainil Sir's teaching in Economics. He makes it engaging and relatable, and I find myself genuinely interested in the subject now."
        </div>
  
    </div>
        
        </div>

        <div className='carousel-inner-container'>
          
        
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
                <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer "/>

           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Ishita Garg</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"Jainil Sir's passion for Business Studies is contagious. His teaching style breaks down complex concepts, and I've seen a remarkable improvement in my grades. He's transformed a challenging subject into an exciting one."
        </div>
  
    </div>
        
        
        
        
        
        </div>


        <div className='carousel-inner-container'>
          
        
        <div className="verified_customer_section">
        <div className="image_review">
            <div className="customer_image">
              
               <img src={user} style={{backgroundColor:'white', border: '1px solid black'}} alt="customer "/>

           </div>
  
            <div className="customer_name_review_status">
                <div className="customer_name">Harshal Shah</div>
                <div className="customer_review">
                <Rating name="read-only" value={value} readOnly />
                </div>
                <div className="customer_status">
                    <div className="customer_bage"> <img src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894" alt=""/> </div>
                    <div className="customer_status_content">Verified Student</div>
                </div>
  
            </div>
        </div>
  
  
        <div className="customer_comment">"Accountancy was a tough nut to crack, but Jainil Sir's patient guidance has made it much simpler. His real-world examples and attention to detail have made me feel confident in tackling accounting questions."
        </div>
  
    </div>
        
        
        
        
        
        </div>

      </Carousel>
    </div>
      </div>
      
      
            <div className='faq'>
            <h4 className='faq-header-txt'>FAQ</h4>
            <div className='faqdiv'>
            { questions.map(question =>
                            <details open={questions.indexOf(question)===0}>
                              <summary >{question.question}</summary>
                              <p className='summary'>{question.answer}</p>  
                              </details>)}
          </div>
            
            </div>

            <footer className="footer">
              <div className="inner-footer" id="contact">
                <a href="https://www.instagram.com/growth___hub/" target='blank'>
                  <InstagramIcon  className='inner-footer-icons' sx={{color: 'white'}}/>
                  </a>
                  <a  href="https://api.whatsapp.com/send/?phone=919769212639" target='blank'>
                      <WhatsAppIcon sx={{color: 'white'}}  className='inner-footer-icons'/>
                  </a>
                  <a  href="https://www.youtube.com/@Growthhub3/featured" target='blank'>
                      <YouTube sx={{color: 'white'}}  className='inner-footer-icons'/>
                  </a>
                  <a  href="https://www.facebook.com/jainil.bane" target='blank'>
                      <Facebook sx={{color: 'white'}}  className='inner-footer-icons'/>
                  </a>
                  {/* <a  href="https://www.facebook.com/jainil.bane" target='blank'>
                      <LinkedIn sx={{color: 'white'}}  className='inner-footer-icons'/>
                  </a> */}
                {/*                 
                  <LinkedInIcon sx={{color: 'white'}} />
                  <FacebookIcon sx={{color: 'white'}} /> */}
              </div>
            </footer>
      
      </>
    )
  }

  // onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}


  // <div className='carousel-inner-container'>
  // <img src="https://i.ibb.co/8x9xK4H/team.jpg" alt='Image 3' className='testimonial-img'/>
  // <h4 className='testimonial4_header'>Client 3</h4>
  // <p className='testimonial4_content' >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
  // </div>
